import { render, staticRenderFns } from "./EntityBlock.vue?vue&type=template&id=2e1ec23e&scoped=true&"
import script from "./EntityBlock.vue?vue&type=script&lang=js&"
export * from "./EntityBlock.vue?vue&type=script&lang=js&"
import style0 from "./EntityBlock.vue?vue&type=style&index=0&id=2e1ec23e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e1ec23e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiSwiperCarousel: require('/var/teamcity-builds/Omara_Staging_Builds_Frontend/components/UI/UiSwiperCarousel.vue').default,UiLazyImg: require('/var/teamcity-builds/Omara_Staging_Builds_Frontend/components/UI/UiLazyImg.vue').default})
